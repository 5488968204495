import {
    AnimationProps,
    motion,
    useAnimation,
    useMotionValue,
    useSpring,
} from "framer-motion"
import { ComponentType, useCallback, useLayoutEffect } from "react"
import { useEffect, useRef, useState } from "react"
import { FrameProps } from "framer"

export function withMouseParallax(Component): ComponentType {
    return (props: FrameProps) => {
        const wrapperRef = useRef<HTMLDivElement | null>(null)
        const [enabled, setEnabled] = useState(false)
        const posX = useMotionValue(0)
        const posY = useMotionValue(0)
        const elScale = useMotionValue(0)

        const springValue = { damping: 40, mass: 1, stiffness: 500 }

        const x = useSpring(posX, springValue)
        const y = useSpring(posY, springValue)
        const scale = useSpring(elScale, springValue)
        const offsetFactor = 4

        const onMouseMove = (e) => {
            const rect = wrapperRef.current?.getBoundingClientRect()

            x.set((e.clientX - rect.left - rect.width / 2) / offsetFactor)
            y.set((e.clientY - rect.top - rect.height / 2) / offsetFactor)
        }

        const onMouseOver = () => {
            scale.set(1)
        }

        const onMouseOut = () => {
            scale.set(0)
        }

        useEffect(() => {
            if (window.matchMedia("(pointer: fine)").matches) {
                setEnabled(true)
            } else {
                setEnabled(false)
            }
        })

        return (
            <Component
                {...props}
                style={{ ...props.style }}
                onMouseOver={enabled ? onMouseOver : undefined}
                onMouseOut={enabled ? onMouseOut : undefined}
                onMouseMove={enabled ? onMouseMove : undefined}
            >
                <motion.div
                    ref={wrapperRef}
                    style={{
                        pointerEvents: "none",
                        x,
                        y,
                        scale,
                    }}
                    initial={false}
                >
                    {props.children}
                </motion.div>
            </Component>
        )
    }
}
